<template>
	<v-row no-gutters>
    <v-tabs v-model="tab" grow background-color="primary" class="mb-1" style="border-radius: 8px 8px 0px 0px">
      <v-tab class="white--text">Notas Usina</v-tab>
      <v-tab class="white--text">Notas Rejeitadas CST - 930</v-tab>
    </v-tabs>

    <v-col cols="12">
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-dialog v-model="dialogErro.status" persistent>
            <CardDialog :dialog="dialogErro" />
          </v-dialog>
          <v-dialog v-model="dialogConfirmacao" max-width="500">
            <v-card>
              <v-card-title class="text-h5 pa-5">Confirma alteração de notas?</v-card-title>
              <v-divider />
              <div class="text-center py-3">
                <v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
              </div>
              <v-card-text
                class="pt-5 text-center text-h6"
              >O período de alteração será igual ao do filtro de pesquisa.</v-card-text>
              <v-divider />
              <v-card-actions class="pa-3">
                <v-spacer />
                <v-btn
                  :loading="carregando"
                  text
                  color="primary"
                  elevation="0"
                  @click="dialogConfirmacao = false"
                >Cancelar</v-btn>
                <v-btn :loading="carregando" color="primary" elevation="0" @click="ajustarNotas()">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <Table
              :pgLimitGet="pgLimit"
              backendDirectory="ti/acerto/notas/usina/listar"
              :busca="{
                dtini: busca.dtinicio,
                dtfim: busca.dtfim,
              }"
              :filter="true"
              :keydown="keydown"
              :headers="{
                idfilial: {nome: 'Cód. Filial', tipo: 'texto'},
                filial: {nome: 'Filial', tipo: 'texto'},
                nota: {nome: 'Nota', tipo: 'texto'},
                idplanilha: {nome: 'Planilha', tipo: 'texto'},
                dtmovimento: {nome: 'Data', tipo: 'texto'},
                modelo: {nome: 'Modelo', tipo: 'texto'},
                flagiss: {nome: 'Flag ISS', tipo: 'texto'},
                idoperacao: {nome: 'Operação', tipo: 'texto'},

              }"
          >
            <v-col class="px-3">
              Acerto Notas Usina
              <v-btn
                x-small
                fab
                color="primary"
                elevation="0"
                class="ml-5"
                @click="dialogConfirmacao = true"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
            <v-col class="px-1">
              <InputDatePicker :outlined="true" :dense="true" v-model="busca.dtinicio" label="Data inicial" />
            </v-col>
            <v-col class="px-1">
              <InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
            </v-col>
          </Table>
        </v-tab-item>
        <v-tab-item>
          <v-dialog v-model="dialogErro.status" persistent>
            <CardDialog :dialog="dialogErro" />
          </v-dialog>
          <v-dialog v-model="dialogConfirmacao2" max-width="500">
            <v-card>
              <v-card-title class="text-h5 pa-5">Confirma o ajuste da nota?</v-card-title>
              <v-divider />
              <div class="text-center py-3">
                <v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
              </div>
              <v-card-text
                  class="pt-5 text-center text-h6"
              >Após o ajuste, a nota tera que ser enviada manualmente no Monitor NF-e</v-card-text>
              <v-divider />
              <v-card-actions class="pa-3">
                <v-spacer />
                <v-btn
                    :loading="carregando"
                    text
                    color="primary"
                    elevation="0"
                    @click="dialogConfirmacao2 = false"
                >Cancelar</v-btn>
                <v-btn :loading="carregando" color="primary" elevation="0" @click="ajustarNotas2()">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
            <Table
                :pgLimitGet="pgLimit"
                backendDirectory="ti/acerto/notas/cst/listar"
                :filter="false"
                :keydown="keydown"
                :headers="{
                  idfilial: {nome: 'Empresa', tipo: 'texto'},
                  nota: {nome: 'Nota', tipo: 'texto'},
                  idclifor: {nome: 'Cliente', tipo: 'texto'},
                  nome: {nome: 'Nome', tipo: 'texto'},
                  codigostatus: {nome: 'Cód. Status', tipo: 'texto'},
                  descricaostatus: {nome: 'Status', tipo: 'texto'},
                  dtmovimento: {nome: 'Data', tipo: 'texto'},
                  idplanilha: {nome: 'Planilha', tipo: 'texto'},
                  ajuste: {nome: 'Corrigir', tipo: 'botao'}
                  ,
                }"
            >
              <v-col class="px-3">
                Acerto Notas CST - 930
              </v-col>
            </Table>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import Table from "../Widgets/Table";
import InputDatePicker from "../Widgets/InputDatePicker";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
	name: "TiAcertos",
	components: { Table, InputDatePicker, CardDialog },
	data: () => ({
    tab: null,
		carregando: false,
		keydown: false,
		dialogConfirmacao: false,
		dialogConfirmacao2: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
	},
	methods: {
		ajustarNotas() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}ti/acerto/notas/usina/ajustar`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
				})
				.then((res) => {
					this.dialogConfirmacao = false;
					this.carregando = false;
					if (res.data) {
						this.keydown = !this.keydown;
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg = "Não foi possível alterar notas!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.dialogConfirmacao = false;
					this.carregando = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Não foi possível alterar notas!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
    ajustarNotas2() {
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}ti/acerto/notas/cst/ajustar`, {
            idempresa: this.idempresa,
            idplanilha: this.idplanilha,
          })
          .then((res) => {
            this.dialogConfirmacao2 = false;
            this.carregando = false;
            if (res.data) {
              this.keydown = !this.keydown;
            } else {
              this.dialogErro.title = "Erro";
              this.dialogErro.msg = "Não foi possível alterar notas!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
            }
          })
          .catch(() => {
            this.dialogConfirmacao = false;
            this.carregando = false;
            this.dialogErro.title = "Erro";
            this.dialogErro.msg = "Não foi possível alterar notas!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
          });
    },
		async init() {},
	},
	created() {
		this.init();
	},
};
</script>